import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { addHoseToCartAction } from "../../actions/addHoseToCart.action";
import { AppButtonRegular } from "../../components/buttons/App_ButtonRegular";
import { HoseImage } from "../../components/hoseImages/HoseImage";
import SummaryRegularElement from "../../components/summary/SummaryRegularElement";
import { theme } from "../../constants/Theme";
import { useAppDispatch } from "../../hooks/useAppDispatch/useAppDispatch";
import IconCloseSvg from "../../icons/Icon_CloseSvg";
import { RootState } from "../../reducers";
import { addHoseToCartServiceTypes } from "../../services/addHoseToCart.service";
import { coverObjectStringify } from "../../utils/coverObjectStringify";

export const HoseDetailsRoute = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    let location = useLocation();
    const { isLogged, user } = useSelector((state: RootState) => state.account);
    const goBack = () => {
        navigate(-1);
    };
    const { hoseDetails, leftFittingDetails, rightFittingDetails, length, ending, sleeveDetails, coverDetails } =
        location?.state?.hoseDetailsData;
    const showOrderbutton = location?.state?.showOrderbutton;
    const hoseData: addHoseToCartServiceTypes = {
        hoseSymbol: hoseDetails.symbol,
        leftFittingSymbol: leftFittingDetails.symbol,
        rightFittingSymbol: rightFittingDetails.symbol,
        sleeveSymbol: sleeveDetails?.symbol,
        length: length,
        ending: ending,
        clientId: isLogged ? user?.userData?.id : 0,
        cover: coverObjectStringify({
            symbol: coverDetails?.symbol,
            length: coverDetails?.length,
            position: coverDetails?.position,
        }),
    };
    const addHoseIdToLocalStorage = (hoseId: string) => {
        let hosesIdList: string[] = [];
        //@ts-ignore
        hosesIdList = JSON.parse(window.localStorage.getItem("hoses")) || [];
        hosesIdList.push(hoseId);
        window.localStorage.setItem("hoses", JSON.stringify(hosesIdList));
    };
    const orderSameHose = () => {
        dispatch(addHoseToCartAction(hoseData))
            .then((r: any) => {
                if (!isLogged) {
                    addHoseIdToLocalStorage(r.action.payload.data);
                }
                navigate("/Cart");
            })
            .catch(() => alert(" Ups, coś poszło nie tak. Spróbuj ponownie."));
        //TODO

        // dispatch(
        //     addHoseWithSameQRToCartAction({
        //         hoseSymbol: location?.state?.hoseDetailsData.qrCode,
        //         userId: user?.userData?.id,
        //     })
        // )
        //     .then((r: any) => {
        //         if (!isLogged) {
        //             addHoseIdToLocalStorage(r.action.payload.data);
        //         }
        //         navigate("/Cart");
        //     })
        //     .catch(() => alert(" Ups, coś poszło nie tak. Spróbuj ponownie."));
    };
    return (
        <ModalRouteWrapper onClick={() => goBack()}>
            <ModalContentWrapper onClick={(e) => e.stopPropagation()}>
                <CloseWrap>
                    <div style={{ cursor: "pointer" }} onClick={() => goBack()}>
                        <IconCloseSvg />
                    </div>
                </CloseWrap>
                <Header>Parametry przewodu</Header>
                <HoseImage
                    leftFitting={{ angle: leftFittingDetails?.kat_zakucia, type: leftFittingDetails?.typ_zakucia }}
                    rightFitting={{ angle: rightFittingDetails?.kat_zakucia, type: rightFittingDetails?.typ_zakucia }}
                />
                <SubHeader style={{ marginTop: 20 }}>Podstawowe</SubHeader>

                <SummaryRegularElement width={"100%"} header="Długość" title={length + " cm"} />
                <SummaryRegularElement width={"100%"} header="Orientacja zakończeń" title={ending + " stopni"} />
                <SubHeader>Przewód</SubHeader>
                <SummaryRegularElement
                    width={"100%"}
                    header="Średnica wewnętrzna"
                    title={hoseDetails.srednica_wewnetrzna}
                />
                <SummaryRegularElement width={"100%"} header="Oploty" title={hoseDetails.oploty} />
                <SummaryRegularElement
                    width={"100%"}
                    header="Ciśnienie robocze"
                    title={hoseDetails.cisnienie_robocze}
                />
                <SummaryRegularElement
                    width={"100%"}
                    header="Ciśnienie rozrywania"
                    title={hoseDetails.cisnienie_rozrywania}
                />
                <SummaryRegularElement width={"100%"} header="Temp. pracy" title={hoseDetails.temperatura_pracy} />
                <SummaryRegularElement
                    width={"100%"}
                    header="Średnica zewnętrzna"
                    title={hoseDetails.srednica_zewnetrzna}
                />
                <SummaryRegularElement width={"100%"} header="Promień gięcia" title={hoseDetails.promien_giecia} />
                <SummaryRegularElement width={"100%"} header="Waga" title={hoseDetails.waga} />

                <SubHeader>Lewe Zakucie</SubHeader>
                <SummaryRegularElement width={"100%"} header="Nazwa" title={leftFittingDetails.nazwa} />
                <SummaryRegularElement width={"100%"} header="System" title={leftFittingDetails.system} />
                <SummaryRegularElement width={"100%"} header="Typ zakucia" title={leftFittingDetails.typ_zakucia} />
                <SummaryRegularElement width={"100%"} header="Kąt zakucia" title={leftFittingDetails.kat_zakucia} />
                <SummaryRegularElement
                    width={"100%"}
                    header="Średnica gwintu"
                    title={leftFittingDetails.srednica_gwintu}
                />
                <SubHeader>Prawe Zakucie</SubHeader>
                <SummaryRegularElement width={"100%"} header="Nazwa" title={rightFittingDetails.nazwa} />
                <SummaryRegularElement width={"100%"} header="System" title={rightFittingDetails.system} />
                <SummaryRegularElement width={"100%"} header="Typ zakucia" title={rightFittingDetails.typ_zakucia} />
                <SummaryRegularElement width={"100%"} header="Kąt zakucia" title={rightFittingDetails.kat_zakucia} />
                <SummaryRegularElement
                    width={"100%"}
                    header="Średnica gwintu"
                    title={rightFittingDetails.srednica_gwintu}
                />
                {coverDetails && (
                    <>
                        <SubHeader>Osłona przewodu</SubHeader>
                        <SummaryRegularElement width={"100%"} header="Nazwa" title={coverDetails?.nazwa} />
                        <SummaryRegularElement
                            width={"100%"}
                            header="Długość"
                            title={coverDetails?.length + " cm"}
                        />
                        <SummaryRegularElement width={"100%"} header="Położenie" title={coverDetails?.position} />
                    </>
                )}
                <div style={{ height: 30 }} />
                {showOrderbutton && (
                    <AppButtonRegular title="Dodaj taki sam przewód do koszyka" onClick={orderSameHose} />
                )}
                <div style={{ height: 15 }} />
                <AppButtonRegular
                    bgColor={theme.color.app_lightBlue}
                    color={theme.color.app_blue2}
                    borderColor={theme.color.app_blue2}
                    title="Zamknij"
                    onClick={() => goBack()}
                />
            </ModalContentWrapper>
        </ModalRouteWrapper>
    );
};

const SubHeader = styled.h2`
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    box-sizing: border-box;
    color: ${({ theme }) => theme.color.black};
    padding: 12px 42px;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey};
`;
const Header = styled.h1`
    margin: 25px 0;
    color: ${({ theme }) => theme.color.black};
    font-size: 20px;
    font-weight: 700;
    align-self: center;
`;
const CloseWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
const ModalContentWrapper = styled.div`
    box-sizing: border-box;
    padding: 15px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100vw;
    height: auto;
    z-index: 2;
    background-color: white;
    @media (min-width: 1000px) {
        width: 580px;
        margin-top: 50px;
        margin-bottom: 100px;
        height: auto;
    }
`;
const ModalRouteWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    overflow: scroll;
    top: 0;
    background-color: rgba(107, 112, 121, 0.4);
    backdrop-filter: blur(2px);
    transition: background-color 400ms ease-in-out;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 11;
`;
