import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { fetchCoversAction } from "../../actions/cover.action";
import {
    setCurrentCoverAction,
    setCurrentCoverLengthAction,
    setCurrentCoverPositionAction,
} from "../../actions/currentHose.action";
import { useAppDispatch } from "../../hooks/useAppDispatch/useAppDispatch";
import { RootState } from "../../reducers";
import { Text, TitleHeader } from "../../styled/typography";
import { ListItemCover, ListItemCoverEmpty } from "../listElements/ListItem_Cover";
import CoverSelectModal from "../modals/CoverSelect_Modal";
import { HoseCoverProperties } from "./HoseCoverProperties";

export const HoseCover = ({ hoseSymbol }: any) => {
    const dispatch = useAppDispatch();
    const coversList = useSelector((state: RootState) => state.cover.covers);
    const { coverData } = useSelector((state: RootState) => state.currentHose.cover);
    const currentHose = useSelector((state: any) => state.currentHose);

    const [modalOpen, setModalOpen] = useState({ data: null, state: false });
    const setCurrentCover = (selectedCover) => {
        let coverLength = currentHose?.cover?.coverLength ?? currentHose?.hose?.length;
        let percentageLength = currentHose?.cover?.percentageLength ?? 1;
        dispatch(setCurrentCoverAction(selectedCover));
        dispatch(setCurrentCoverLengthAction({ length: coverLength, percentageLength: percentageLength }));
    };

    const clearCover = () => {
        dispatch(setCurrentCoverAction(null));
        dispatch(setCurrentCoverLengthAction({ length: null, percentageLength: null }));
        dispatch(setCurrentCoverPositionAction(null));
    };

    useEffect(() => dispatch(fetchCoversAction(hoseSymbol)), []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Wrapper>
            <TitleHeader>Osłona przewodu</TitleHeader>
            <Text style={{ marginTop: 5, marginBottom: 10 }}>
                Możesz wybrać dodatkową osłonę chroniącą przed przetarciem lub wysoką temperaturą
            </Text>
            <ListWrapper>
                <ListItemCoverEmpty isSelected={coverData === null} onSelect={clearCover} />
                {coversList?.map((item: any, index) => (
                    <ListItemCover
                        isSelected={coverData?.Symbol === item?.Symbol}
                        onSelect={() => setCurrentCover(item)}
                        key={index}
                        data={item}
                        openModal={({ data, state }) => setModalOpen({ data, state })}
                    />
                ))}
            </ListWrapper>
            {coverData !== null && <HoseCoverProperties />}
            <CoverSelectModal
                data={modalOpen.data}
                isOpen={modalOpen.state}
                setOpen={() => setModalOpen({ data: modalOpen?.data, state: !modalOpen.state })}
            />
        </Wrapper>
    );
};
const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;
const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-top: 11px;
    margin-bottom: 28px;
`;
