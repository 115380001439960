import ReactModal from "react-modal";
import styled from "styled-components";
import { theme } from "../../constants/Theme";
import IconDiameterOuterSvg from "../../icons/Icon_DiameterOuterSvg";
import IconTemperatureSvg from "../../icons/Icon_TemperatureSvg";
import { AppButtonRegular } from "../buttons/App_ButtonRegular";
import { HoseParameterInModal } from "../elements/HoseParameterInModal";
import "./modalAppearance.css";

const CoverSelectModal = ({ isOpen, setOpen, data }: any) => {
    const iconSize = 16;
    const iconColor = theme.color.app_blue;
    return (
        <div>
            <ReactModal
                className="modalBody modalBodyCover"
                overlayClassName="modalAppearance"
                isOpen={isOpen}
                ariaHideApp={false}
                contentLabel="onRequestClose Example"
                onRequestClose={setOpen}
                shouldCloseOnOverlayClick={true}>
                <ModalContentWrapper>
                    <div>
                        <HeaderWrap>
                            <HeaderTitle>{data?.nazwa}</HeaderTitle>
                            <HeaderSymbol>{data?.Symbol}</HeaderSymbol>
                        </HeaderWrap>
                        <ImageWrap>
                            <Image src={`${process.env.REACT_APP_API_URL}/${data?.obrazki[0]}`} alt="hose_cover" />
                        </ImageWrap>
                        <Description>{data?.nazwa}</Description>
                        <Description style={{ paddingTop: 0 }}>{data?.krotki_opis}</Description>
                        <Title>Parametry:</Title>
                        <ParamsWrap>
                            <HoseParameterInModal
                                title="Grubość ścianki"
                                icon={<IconDiameterOuterSvg SIZE={iconSize} COLOR={iconColor} />}
                                value={data?.srednica_zewnetrzna}
                            />
                            <HoseParameterInModal
                                title="Temperatura pracy"
                                icon={<IconTemperatureSvg SIZE={iconSize} COLOR={iconColor} />}
                                value={data?.temperatura_pracy}
                            />
                        </ParamsWrap>
                    </div>
                    <ButtonsWrap>
                        <AppButtonRegular
                            title="Wróć"
                            onClick={setOpen}
                            bgColor={theme.color.app_lightBlue}
                            borderColor={theme.color.app_blue2}
                            color={theme.color.app_blue2}
                        />
                        <div style={{ width: 10 }} />

                        <AppButtonRegular title="Wybierz" onClick={setOpen} />
                    </ButtonsWrap>
                </ModalContentWrapper>
            </ReactModal>
        </div>
    );
};

export default CoverSelectModal;

export const ButtonsWrap = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 18px 18px 18px 18px;
    position: sticky;
    bottom: 0;
    background-color: white;
`;
const ParamsWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const Title = styled.p`
    font-weight: 700;
    font-size: 16px;
    color: ${({ theme }) => theme.color.black};
    margin: 0;
    align-self: flex-start;
    padding: 18px;
`;
const HeaderTitle = styled.p`
    font-weight: 700;
    font-size: 18px;
    color: ${({ theme }) => theme.color.black};
    margin: 0;
`;
const HeaderWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px 11px 18px;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey};
    box-sizing: border-box;
    width: 100%;
`;
const ModalContentWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 13px 0 0 0;
    height: 100%;
`;
const HeaderSymbol = styled.p`
    font-weight: 400;
    font-size: 10px;
    color: ${({ theme }) => theme.color.app_grey2};
    text-transform: uppercase;
    margin: 0;
`;
const Description = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.color.black};
    padding: 18px;
    margin: 0;
`;
const Image = styled.img`
    max-height: 75px;
`;
const ImageWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey};
    padding: 15px 0 20px 0;
    width: 100%;
`;
